import { ContentBlockType } from '@tytapp/content';
import { ShowBrandTeaserBlock } from './show-brand-teaser-block';
import { Injector } from '@angular/core';
import { ShowsApi } from '@tytapp/api';

export * from './show-brand-teaser-block';

export const SHOW_BRAND_MEDIA_TEASER_BLOCK_TYPE: ContentBlockType<ShowBrandTeaserBlock> = {
    id: 'show-brand-media-teaser',
    label: 'Show Brand Media Teaser',
    icon: 'not_started',
    template: {
        showId: '5Rz3k3CD7yyU488YokUoWi',
        numClips: 2
    },
    preview: {
        showId: '5Rz3k3CD7yyU488YokUoWi',
        numClips: 2
    },
    categoryId: 'dynamic-content',
    deferredEditorComponent: () => import('./show-brand-teaser-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./show-brand-teaser-block.view').then(m => m.ViewComponent),
    earlyPreloadForViewing(injector: Injector, block: ShowBrandTeaserBlock) {
        injector.get(ShowsApi).all();
    }
}


