<div class="episode-container">
  <h1 class="episode-title">{{ showName }}: Latest Videos</h1>
  <div class="episode-main">
    <div class="episode-image">
      <div class="square-image"
        [responsiveBackgroundImage]="squareImage"
        [widthFactor]="0.1"
        [mobileWidthFactor]="0.1">
      </div>
    </div>
    <div class="episode-content">
      <section class="episode-list">
        <a [routerLink]="linkForShow(episode)" class="clip-item">
          <div>
            <time [attr.datetime]="episode?.[0]?.date">{{episode?.[0]?.date | date: 'longDate'}}</time>
            <p class="episode-description">
              {{ episode?.[0]?.full_length_vods?.[0]?.description }}
            </p>
            <tyt-content-badge
              [type]="'episode'"
              [premium]="episode?.[0].full_length_vods[0]?.is_premium"
              [unlocked]="episode?.[0]?.unlock_premium"
              [topic]="episode?.[0]?.topics?.[0]">
            </tyt-content-badge>
          </div>
        </a>
      </section>
      <h2 class="clips-title">Clips from this show</h2>
      <div class="clips-container">
        @if (numClips > 0) {
          <div class="clip-list">
            @for (clip of clips; track clip) {
              <a [routerLink]="linkForClipItem(episode, clip)" class="clip-item">
                <div class="clip-icon">
                  <mat-icon class="play-icon">play_arrow</mat-icon>
                </div>
                <div class="clip-content">
                  <h3 class="clip-title">{{ clip.title }}</h3>
                  <div class="clip-details">
                    <div class="addendum">
                      <tyt-content-badge
                        [type]="'clip'"
                        [premium]="clip.is_premium"
                        [unlocked]="clip.unlock_premium"
                        [topic]="clip.topics?.[0]">
                      </tyt-content-badge>
                      <ul class="facts">
                        <li class="fact-item">
                          <span class="clip-duration">{{ clip.duration | duration }}</span>
                        </li>
                        <li class="fact-item">
                          <span class="clip-comments">
                            <tyt-comments-count identifier="vod_{{clip.id}}"></tyt-comments-count>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            }
            @if(numClips > 2) {
              <a (click)="toggleMore()" class="see-more-button">
                {{ more ? 'Show Less' : 'Show More' }}
              </a>
            }
          </div>
        } @else {
          <p class="no-clips">No clips available in this episode.</p>
        }
      </div>
    </div>
  </div>
</div>