import { Component, Input,inject } from '@angular/core';
import { ArticlesService } from '@tytapp/articles';
import { ShowsApi } from '@tytapp/api';

@Component({
    selector: 'tyt-show-brand-teaser',
    templateUrl: './show-brand-teaser.component.html',
    styleUrls: ['./show-brand-teaser.component.scss'],
})
export class ShowBrandTeaserComponent {

    constructor(private articlesService: ArticlesService,private showsApi: ShowsApi,) {
    }
    @Input() showId: string;
    @Input() numClips: number;

    showName?: string;
    squareImage?: string;
    clipsInit: any[] = [];
    clipsMore: any[] = [];
    clips: any[] = [];
    episode?: any[];
    more: boolean = false;

    async ngOnInit() {
        await this.showsApi.getShowProductions(this.showId, 0, 1).subscribe({
            next: (productionsResponse) => {
                if (productionsResponse.length) {
                    this.episode = productionsResponse.length ? productionsResponse : [];
                    this.showName = productionsResponse[0].show.name;
                    this.squareImage = productionsResponse[0].show.logo_square;
                    const allClips = productionsResponse[0].vod_clips || [];
                    if(allClips.length < this.numClips){
                        this.numClips = allClips.length;
                    }
                    if (allClips.length > 2) {
                        this.clipsInit = allClips.slice(0, 2);
                        this.clipsMore = allClips.slice(2,this.numClips);
                    } else {
                        this.clipsInit = allClips;
                        this.clipsMore = [];
                    }
                    this.clips = this.clipsInit;
                } else {
                    this.clipsInit = [];
                    this.clipsMore = [];
                }
            },
            error: (err) => {
                console.error('Error fetching show productions:', err);
            }
        });
    }
    toggleMore() {
        this.more = !this.more;
        this.clips = this.more ? this.clipsInit.concat(this.clipsMore) : this.clipsInit;
    }

    linkForClipItem(episode: any, clip: any) {
        if (episode) {
            return `/shows/${episode[0].show.slug}/${episode[0].date}/clips/${clip.id}`;
        }
        return `javascript:;`;
    }

    linkForShow(episode: any) {
        if (episode) {
            if (episode[0].full_length_vods.length === 0) {
                return `/shows/${episode[0].show.slug}`;
            }else{
                return `/shows/${episode[0].show.slug}/${episode[0].date}/watch/${episode[0].full_length_vods[0].id}`;
            }
        }
        return `javascript:;`;
    }
}
